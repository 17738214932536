import React from "react";
import HorizontalBar from "./resuableComponent/horizontalBar";
const OurWork = () => {
  return (
    <>
      <div className=" flex flex-row">
        <HorizontalBar text={"OUR WORK"} />
      </div>
      <div className="px-5 sm:px-8">
        <p className="mt-4 font-semibold text-lg 2xl:mx-12 text-[#04A9BB]">
          Our grassroots-led intervention programs, help increase education,
          income, and health for tribal communities in remote rural regions. We
          develop and facilitate vocational, career, and skills training to
          middle grade and high school students to build a better future. Our
          programs in football, robotics and programming is helping students
          gain knowledge and proficiency to become professional football
          players, sports coaches, programmers, agriculturists, and
          entrepreneurs.
        </p>
      </div>
      <div className="lg:flex mb-4 m-5  mx-auto 2xl:mx-16 mt-10">
        <div className="w-full lg:w-1/3  overflow-hidden shadow-lg m-5 relative flex flex-col">
          <img
            className="w-full"
            src="https://tailwindcss.com/img/card-top.jpg"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-4 h-full  bg-[#F05A51]">
            <div className="font-bold text-xl mb-2 flex text-white  justify-center">
              Education
            </div>
            <p className="text-white text-base  text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
          </div>
        </div>

        <div className="w-full lg:w-1/3  overflow-hidden shadow-lg m-5">
          <img
            className="w-full"
            src="https://tailwindcss.com/img/card-top.jpg"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-4 bg-[#F05A51]">
            <div className="font-bold text-xl mb-2 flex  text-white justify-center">
              SPORTS
            </div>
            <p className="text-white text-base  text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatibus quia, nulla! Maiores et perferendis eaque,
              exercitationem praesentium nihil.
            </p>
          </div>
        </div>

        <div className="w-full lg:w-1/3  overflow-hidden shadow-lg m-5">
          <img
            className="w-full"
            src="https://tailwindcss.com/img/card-top.jpg"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-4 bg-[#F05A51] text-white">
            <div className="font-bold text-xl mb-2 flex  justify-center">
              TECHNOLOGY
            </div>
            <p className="text-white text-base text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatibus quia, nulla! Maiores et perferendis eaque,
              exercitationem praesentium nihil.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurWork;
