import React from "react";
import OurWork from "./OurWork";

const Homepage = () => {
  return (
    <div>
      <OurWork />
    </div>
  );
};

export default Homepage;
